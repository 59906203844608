import React from 'react'
import { Container, Row, Col } from 'reactstrap'
import { PhotoSwipeGallery } from 'react-photoswipe'
let options = {
    //http://photoswipe.com/documentation/options.html
}
  
let getThumbnailContent = item => {
    return (
        <div
            className="gallery-item"
            itemProp="associatedMedia"
            itemScope=""
            itemType="http://schema.org/ImageObject"
        >
            <a data-size="750x1002" href={item.src} itemProp="contentUrl">
            <img
                alt="..."
                className="vertical-image img-rounded img-responsive"
                itemProp="thumbnail"
                src={item.src}
            />
            </a>
            <figcaption className="gallery-caption" itemProp="caption description">
            {item.title}
            </figcaption>
      </div>
    )
}
const photoSwipeItems = [
    {
      src: require("assets/images/galeria/gal1.jpg"),
      title: "",
      w:"1280",
      h:"960"
    },
    {
      src: require("assets/images/galeria/gal3.jpg"),
      title: "",
      w:"1280",
      h:"960"
    },
    {
      src: require("assets/images/galeria/gal9.jpg"),
      title: "",
      w: "1280",
      h: "960"
    },
    {
      src: require("assets/images/galeria/gal5.jpg"),
      title: "",
      w:"1280",
      h:"960"
    },
    {
      src: require("assets/images/galeria/gal4.jpg"),
      title: "",
      w:"720",
      h:"960"
    },
    {
      src: require("assets/images/galeria/gal2.jpg"),
      title: "",
      w:"720",
      h:"960"
    },
    
    {
      src: require("assets/images/galeria/gal8.jpg"),
      title: "",
      w:"720",
      h:"1010"
    },
    {
      src: require("assets/images/galeria/gal14.png"),
      title: "",
      w: "440",
      h: "558"
    },
    {
        src: require("assets/images/galeria/gal10.jpg"),
        title: "",
        w: "1280",
        h: "1280"
    },
    {
      src: require("assets/images/galeria/gal18.png"),
      title: "",
      w: "567",
      h: "588"
    },
    
    {
      src: require("assets/images/galeria/gal12.jpg"),
      title: "",
      w: "1280",
      h: "1280"
    },
    {
      src: require("assets/images/galeria/gal11.jpg"),
      title: "",
      w: "1280",
      h: "1442"
  },
    
    {
      src: require("assets/images/galeria/gal15.png"),
      title: "",
      w: "696",
      h: "582"
    },
    {
      src: require("assets/images/galeria/gal16.png"),
      title: "",
      w: "628",
      h: "666"
    },
    {
      src: require("assets/images/galeria/gal17.png"),
      title: "",
      w: "708",
      h: "722"
    },
    {
      src: require("assets/images/galeria/gal20.png"),
      title: "",
      w: "793",
      h: "714"
    },
    {
      src: require("assets/images/galeria/gal13.jpg"),
      title: "",
      w: "860",
      h: "562"
    },
    {
      src: require("assets/images/galeria/gal19.jpg"),
      title: "",
      w: "924",
      h: "626"
    },
    {
      src: require("assets/images/galeria/gal6.jpg"),
      title: "",
      w:"1280",
      h:"622"
    },
    {
      src: require("assets/images/galeria/gal7.jpg"),
      title: "",
      w:"1280",
      h:"622"
    },
    {
      src: require("assets/images/galeria/gal21.jpg"),
      title: "",
      w:"1280",
      h:"696"
    },
    {
      src: require("assets/images/galeria/gal22.jpg"),
      title: "",
      w:"1280",
      h:"696"
    },
    {
      src: require("assets/images/galeria/gal23.jpg"),
      title: "",
      w:"1280",
      h:"696"
    },
    {
      src: require("assets/images/galeria/gal24.jpg"),
      title: "",
      w:"1280",
      h:"696"
    },
    {
      src: require("assets/images/galeria/gal25.jpg"),
      title: "",
      w:"1280",
      h:"696"
    },
    {
      src: require("assets/images/galeria/gal26.jpg"),
      title: "",
      w:"1280",
      h:"696"
    },
    {
      src: require("assets/images/galeria/gal27.jpg"),
      title: "",
      w:"1280",
      h:"696"
    },
    {
      src: require("assets/images/galeria/gal28.jpg"),
      title: "",
      w:"1280",
      h:"696"
    },
    {
      src: require("assets/images/galeria/gal29.jpg"),
      title: "",
      w:"1280",
      h:"696"
    },
    {
      src: require("assets/images/galeria/gal30.jpg"),
      title: "",
      w:"1280",
      h:"696"
    },
    {
      src: require("assets/images/galeria/gal31.jpg"),
      title: "",
      w:"1280",
      h:"696"
    }
  ];
const Galeria = () => {
    return(
        <div className="section text-center pt-5" id="galeria">
            <Container>
                <Row>
                    <Col className="ml-auto mr-auto text-center" md="8">
                        <h2 className="title">Galería</h2>
                    </Col>
                </Row>
                <Row>
                    <PhotoSwipeGallery
                        items={photoSwipeItems}
                        options={options}
                        thumbnailContent={getThumbnailContent}
                    />
                </Row>
            </Container>
        </div>
    )
}
export default Galeria