import React from "react";
// reactstrap components
import { CardContacto, Telefono } from './styles'
import Phone from '../Icons/Phone'
import Mail from '../Icons/Mail'
import WhatsApp from '../Icons/WhatsApp'
import Formulario from './Formulario.js'
import { Card, CardTitle, Container, Row, Col } from "reactstrap";

function SectionContactUs() {
  return (
    <>
      <div className="section section-contactus cd-section" id="contact-us">
        {/* ********* CONTACT US 1 ********* */}
        <div
          className="contactus-1 section-image"
          style={{
            backgroundImage:
              "url(" + require("assets/images/bg-contacto.jpg") + ")",
          }}
        >
          <Container>
            <Row>
              <Col className="ml-auto mr-auto" md="10">
                <Card className="card-contact no-transition">
                  <CardTitle className="text-center" tag="h3">
                    Contáctanos
                  </CardTitle>
                  <Row>
                    <Col className="ml-auto" md="5">
                      <h4 className="info-title pl-3 pl-md-0">Llámanos</h4>
                      <CardContacto>
                          <Telefono>
                              <Phone size="32" color="#1EBEA5"/>
                              <a className="pl-2 pt-1" href="tel:+56995354992">+56 9 95354992</a>
                          </Telefono>  
                      </CardContacto>
                      <hr/>
                      <CardContacto>
                          <Telefono>
                              <Mail size="32" color="#1EBEA5"/>
                              <a className="pl-2 pt-1" href="mailto:encontremilugarcorredora@gmail.com">encontremilugarcorredora@gmail.com</a>
                          </Telefono>  
                      </CardContacto>
                      <hr/>               
                      <CardContacto>
                          <Telefono>
                              <WhatsApp size="32" color="#1EBEA5"/>
                              <a className="pl-2 pt-1" href="https://wa.me/+56995354992">+56 9 95354992</a>
                          </Telefono>  
                      </CardContacto>
                      <hr/>                        
                    </Col>
                    <Col className="mr-auto" md="5">
                      <Formulario/>
                    </Col>
                  </Row>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
        {/* ********* END CONTACT US 1 ********* */}
      </div>
    </>
  );
}

export default SectionContactUs;
