import React from 'react'
import { Container, Row, Col, Button } from 'reactstrap'
import { Link } from 'react-router-dom'
const DescargarPlano = () => {
    return(
        <div className="section-gray text-center py-5" id="descargar">
            <Container>
                <Row>
                    <Col className="ml-auto mr-auto text-center" md="8">
                        <Button tag={Link} to="./plano.pdf" target="blank">Descargar Plano</Button>
                    </Col>
                </Row>     
            </Container>
        </div>
    )
}
export default DescargarPlano