import React from 'react'
import { Container, Row, Col } from 'reactstrap'

const Proyecto = () => {
    return(
        <>
            <div className="section section-feature cd-section">
            {/* ********* FEATURES 1 ********* */}
            <div className="features-1" id="elproyecto">
                <Container>
                <Row>
                    <Col className="ml-auto mr-auto text-center" md="9">
                    <h2 className="title">El Proyecto</h2> 
                    <h5 className="description text-justify">
                      ¡¡Un refugio natural bañado por las cristalinas aguas del río Calle Calle, 
                      que se encuentra Cerca de todo!! A tan solo 45 minutos de Valdivia donde 
                      podrás disfrutar de toda su gastronomía, a 5 min de la ciudad de Los lagos donde 
                      podrás encontrar supermercados, centros asistenciales, bencineras, bancos, 
                      restaurantes y todo lo que necesites, a 3 min de la ruta 5 sur que en palabras simples, 
                      te conecta con todo Chile, y si deseas desplazarte rápido a tan solo 25 minutos del 
                      aeropuerto Pichoy. 
                    </h5>
                    <h5 className="description text-justify">
                      Puedes estar en poco tiempo, en diferentes centros de ski, volcanes, disfrutar 
                      termas preciosas inmersas en bosques nativos, visitar parques nacionales, 
                      reservas con árboles milenarios y especies únicas de la zona o elegir playas 
                      selváticas y pasar el día en ellas, sector bordeado de los lagos más hermosos de Chile, 
                      con una excelente conectividad con la ruta SIETE LAGOS.
                    </h5>
                    <h5 className="description text-justify">
                      Altos del Calle Calle posee un acceso directo a las cristalinas aguas del río Calle Calle, 
                      lugar exclusivo y conocido por la pesca del Salmón a Orilla del mismo loteo, 
                      proyectamos una bajada para embarcaciones pequeñas o kayaks en un Área Verde para conectarte
                      directo con el único río navegable de Chile, en toda su inmensa extensión de 55 km. 
                      Sus aguas recorren desde el hermoso Lago Riñihue hasta el mismo Océano Pacifico 
                      cruzando por la conocida costanera de Valdivia donde tendrás contacto directo con 
                      la espesa selva valdiviana con árboles y animales únicos en el mundo y que no dejará 
                      de sorprender a quienes la visiten. 
                      Diversas especies como el monito del monte, pudú, puma, gato guiña, ranita de Darwin y 
                      el cisne de cuello negro.
                    </h5>
                    <h5 className="description text-justify">
                      ¡¡Atrévete!!... ya puedes Vivir la Magia del sur, con la cercanía que necesitas y 
                      con las comodidades que tu y tu FAMILIA merecen.
                    </h5>
                    
                    </Col>
                </Row>
                </Container>
            </div>
        {/* ********* FEATURES 5 ********* */}
        <div
          id="descripcion"
          className="features-5 section-image"
          style={{
            backgroundImage:
              "url(" +
              require("assets/images/bg-description.jpg") +
              ")",
          }}
        >
          <Container>
            <Row>
              <div className="ml-auto mr-auto">
                <h2 className="title text-center">
                  Descripción
                </h2>
              </div>
            </Row>
            <Row>
              <Col className="ml-auto" sm="5">
                <div className="info">
                  <div className="icon">
                    <i aria-hidden={true} className="nc-icon icon-map-pin"/>
                  </div>
                  <h4 className="title text-white">Ubicación</h4>
                  <p className="text-white">
                    Conexión Insuperable: a 3 min de la ruta 5 sur, a 5 min de la ciudad de Los Lagos y a 25 min del Aeropuerto Pichoy.<br/>
                  </p>
                </div>
              </Col>
              <Col className="mr-auto" sm="5">
                <div className="info">
                  <div className="icon">
                    <i aria-hidden={true} className="nc-icon icon-d-edit" />
                  </div>
                  <h4 className="title text-white">Características</h4>
                  <p className="text-white">
                    Parcelas en prados de 5.000 m2, casi 100% planas y limpias, bañadas por las cristalinas aguas del río Calle Calle.
                  </p>
                </div>
              </Col>
            </Row>
            <Row className="bottom-line">
              <Col className="ml-auto" sm="5">
                <div className="info">
                  <div className="icon">
                    <i aria-hidden={true} className="nc-icon icon-drop-2" />
                  </div>
                  <h4 className="title text-white">Urbanización y Seguridad</h4>
                  <p className="text-white">
                    Factibilidad de luz y agua. <br/>
                    Amplios caminos. <br/>
                    Acceso Controlado mediante portón de ingreso. Luces de Movimiento.
                  </p>
                </div>
              </Col>
              <Col className="mr-auto" sm="5">
                <div className="info">
                  <div className="icon">
                    <i aria-hidden={true} className="nc-icon icon-rowing" />
                  </div>
                  <h4 className="title text-white">Área Común</h4>
                  <p className="text-white">
                    Área verde común con juegos para niños y con la proyección de una bajada para embarcaciones pequeñas o kayaks para conectar directamente con el único Río Navegable en todo Chile en toda su extensión, El río calle Calle es el desagüe natural del Lago Riñihue y conecta por 55 km hasta el Océano Pacifico.
                  </p>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
        {/* ********* END FEATURES 5 ********* */}
        {/* ********* FEATURES 4 ********* */}
        <div className="features-4" id="urbanizacion">
          <Container>
            <Row>
              <Col className="ml-auto mr-auto text-center" md="8">
                <h2 className="title">Urbanización</h2>
              </Col>
            </Row>
            <Row>
              <Col md="6">
                <div className="ipad-container">
                  <img
                    alt="..."
                    src={require("assets/images/urbanizacion.jpg")}
                  />
                  <p>* Imagen representativa y referencial sujeta a cambios.</p>
                </div>
              </Col>
              <Col className="offset-1" md="4">
                <div className="info info-horizontal pt-4">
                  <div className="icon icon-info">
                    <i aria-hidden={true} className="nc-icon icon-property-location" />
                  </div>
                  <div className="description">
                    <h4 className="info-title">Loteo</h4>
                    <p>
                      23 sitios de 5.000 m2 y más.
                    </p>
                  </div>
                </div>
                <div className="info info-horizontal pt-3">
                  <div className="icon icon-success">
                    <i aria-hidden={true} className="nc-icon icon-mortgage" />
                  </div>
                  <div className="description">
                    <h4 className="info-title">Entrega</h4>
                    <p>
                      Inmediata
                    </p>
                  </div>
                </div>
                <div className="info info-horizontal pt-3">
                  <div className="icon icon-danger">
                    <i aria-hidden={true} className="nc-icon nc-tie-bow" />
                  </div>
                  <div className="description">
                    <h4 className="info-title">Urbanización</h4>
                    <ul className="ml-0 pl-3">
                      <li className="texto-normal">Camino estabilizado y compactado.</li>
                      <li className="texto-normal">Factibilidad de Agua</li>
                      <li className="texto-normal">Factibilidad de Luz</li>
                      <li className="texto-normal">Zona Común con acceso a Río</li>
                      <li className="texto-normal">Portón de acceso</li>
                    </ul>
                  </div>
                </div>
                <div className="info info-horizontal pt-3">
                  <div className="icon icon-info">
                    <i aria-hidden={true} className="nc-icon icon-currency-dollar" />
                  </div>
                  <div className="description">
                    <h4 className="info-title">Forma de Pago</h4>
                    <p>
                      Solicita mayor información.
                    </p>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
    </>

    )
}
export default Proyecto


