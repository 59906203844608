import React from 'react'
import { Container, Row, Col } from 'reactstrap'
function Relacionados(){

    return(
        <div className="section pb-5" id="ubicacion">
			<Container>
				<Row>
					<Col sm="12">
						<h2 className="text-center my-5">Videos Relacionados</h2>
					</Col>
				</Row>
				<Row>
					<Col sm="12" md="4">
                        <div class="embed-responsive embed-responsive-4by3">
                            <iframe title="video1" className="embed-responsive-item" src="https://www.youtube.com/embed/GnYSZa7AxSw" allowfullscreen></iframe>
                        </div>
					</Col>
                    <Col sm="12" md="4">
                        <div class="embed-responsive embed-responsive-4by3">
                            <iframe title="video2" className="embed-responsive-item" src="https://www.youtube.com/embed/849F1yzgaLI" allowfullscreen></iframe>
                        </div>
					</Col>
                    <Col sm="12" md="4">
                        <div class="embed-responsive embed-responsive-4by3">
                            <iframe title="video3" className="embed-responsive-item" src="https://www.youtube.com/embed/MEqgPMaSltw" allowfullscreen></iframe>
                        </div>
					</Col>
				</Row>
			</Container>
		</div>
    )
}
export default Relacionados