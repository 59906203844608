import React from "react";
import { Container, Row, Col } from "reactstrap";
import Map from "./Map.js";
function Ubicacion() {
	return (
		<div className="section pb-5" id="ubicacion">
			<Container>
				<Row>
					<Col sm="12">
						<h2 className="text-center my-5">Ubicación</h2>
					</Col>
				</Row>
				<Row>
					<Col>
						<Map />
					</Col>
				</Row>
			</Container>
		</div>
	);
}
export default Ubicacion;
