/*eslint-disable*/
import React from "react";

// reactstrap components
import { Container, Row, Col } from "reactstrap";
import INSTAGRAM from 'assets/images/instagram.png'
import FACEBOOK from 'assets/images/logo-facebook.png'
// core components

function Footer() {
  return (
    <>
      <footer className="footer footer-black py-5">
        <Container>
          <Row className="ft justify-content-center">
            <Col className="col-12 col-md-4">
                <span className="ml-5 texto-small">
                  SÍGUENOS EN INSTAGRAM 
                  <a href="https://instagram.com/altosdelcallecalle" target="blank"><img style={{width:'40px'}} src={INSTAGRAM} alt="icon-instagram"/></a>
                </span>   
            </Col>
            <Col className="col-12 col-md-4">
                <span className="ml-5 texto-small">
                  SÍGUENOS EN FACEBOOK
                  <a href="https://www.facebook.com/altosdelcallecalle/" target="blank"><img style={{width:'36px',paddingLeft:'5px'}} src={FACEBOOK} alt="icon-facebook"/></a>
                </span>   
            </Col>
            <Col className="col-12 col-md-4 pt-3">
              <div className="ml-5 text-left text-md-right">
                <a href="https://instagram.com/altosdelcallecalle" target="_blank"><p className="texto-small">@ALTOSDELCALLECALLE</p></a>
                <a href="mailto:encontremilugarcorredora@gmail.com" target="_blank"><p className="texto-small">encontremilugarcorredora@gmail.com</p></a>
                <a href="tel:+56995354992"><p className="texto-small">+56 9 95354992</p></a>
                
              </div>
            </Col>
          </Row>
        </Container>
      </footer>
    </>
  );
}

export default Footer;