import React from 'react'
import { Container, Row, Col } from 'reactstrap'

const Masterplan = () => {
    return(
        <div className="section text-center" id="masterplan360">
            <Container>
                <Row>
                    <Col className="ml-auto mr-auto text-center" md="8">
                        <h2 className="title">Masterplan 360º</h2>
                    </Col>
                </Row>
                <div className="embed-responsive-vt">
                    <iframe className="embed-responsive-item-vt" title='masterplan' src="https://www.lanube360.com/altos-callecalle/"></iframe>
                </div>    
            </Container>
        </div>
    )
}
export default Masterplan